import {
	mdiMagnify,
	mdiWeb,
	mdiChevronRight,
	mdiChevronLeft,
	mdiCheckCircle,
	mdiFacebook,
	mdiTwitter,
	mdiInstagram,
	mdiLinkedin,
	mdiYoutube,
	mdiMenu,
	mdiPlay,
	mdiCalendar,
	mdiClockOutline,
	mdiCheckBold,
	mdiCheck,
	mdiChartLineVariant,
	mdiFilePdfBox,
	mdiEmailOutline,
	mdiFax,
	mdiPhoneOutline,
	mdiCellphone,
	mdiMapMarkerOutline,
	mdiVolumeOff,
	mdiVolumeHigh,
	mdiContentCopy,
	mdiCheckboxMultipleMarkedCircle,
	mdiMenuDown,
	mdiWindowClose,
	mdiMinus,
	mdiApple,
	mdiGooglePlay,
	mdiMapMarker,
	mdiDownload,
	mdiAlertCircle,
	mdiArrowRight,
  mdiArrowLeft,
  mdiMonitor,
  mdiPlus,
  mdiTrashCan


} from "@mdi/js";
export default ({ app, i18n, $vuetify }, inject) => {
	const isRTL = function () {
		return i18n.localeProperties.dir === "rtl";
	};
	inject("icons", {
		search: mdiMagnify,
		language: mdiWeb,
		get chevronEnd() {
			return isRTL() ? mdiChevronLeft : mdiChevronRight;
		},
		get chevronStart() {
			return isRTL() ? mdiChevronRight : mdiChevronLeft;
		},
		checkCircle: mdiCheckCircle,
		// social media
		facebook: mdiFacebook,
		twitter: mdiTwitter,
		instagram: mdiInstagram,
		linkedin: mdiLinkedin,
		youtube: mdiYoutube,

		menu: mdiMenu,
		play: mdiPlay,
		calender: mdiCalendar,
		clock: mdiClockOutline,
		mdiCheckBold,
		chart: mdiChartLineVariant,
		pdf: mdiFilePdfBox,
		email: mdiEmailOutline,
		general_inquiries_email: mdiEmailOutline,
		support_email: mdiEmailOutline,
		fax: mdiFax,
		tel: mdiPhoneOutline,
		mobile: mdiCellphone,
		address: mdiMapMarkerOutline,
		muted: mdiVolumeOff,
		volumeHigh: mdiVolumeHigh,
		copy: mdiContentCopy,
		check: mdiCheck,
		checkboxCircle: mdiCheckboxMultipleMarkedCircle,
		mdiMenuDown,
		mdiWindowClose,
		mdiMinus,
		mdiApple,
		mdiGooglePlay,
		map: mdiMapMarker,
		download: mdiDownload,
		mdiAlertCircle,
		mdiArrowRight,
    mdiArrowLeft,
    mdiMonitor,
    mdiPlus,
    mdiTrashCan

	});
};
