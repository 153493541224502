
import merge from '~lodash.mergewith'
import { init } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F2b08a1c98fe40c4de0f6fbfe8e2c8e54@o4504231067582464.ingest.sentry.io\u002F4506054002671616",
    environment:"production-s2",
    denyUrls:["https:\u002F\u002Fcdn.livechatinc.com\u002Ftracking.js"],
    ignoreErrors:[new RegExp("ReferenceError: Can't find variable: gtag$", ""),new RegExp("undefined is not an object \\(evaluating 'e._isDestroyed'\\)$", ""),new RegExp("Unexpected token '\u003C'$", ""),new RegExp("ResizeObserver loop limit exceeded$", ""),new RegExp("undefined is not an object \\(evaluating 'a.M'\\)$", ""),new RegExp("Non-Error promise rejection captured$", ""),new RegExp("Maximum call stack size exceeded$", ""),new RegExp("Cannot read properties of undefined (reading 'path')$", ""),new RegExp("Cannot read properties of undefined (reading 'matched')$", ""),new RegExp("a is undefined", ""),new RegExp("TypeError: a is undefined", ""),new RegExp("SecurityError: Failed to execute 'replaceState' on 'History'", ""),new RegExp("NavigationDuplicated: Avoided redundant navigation to current location", ""),new RegExp("Page Error Handler:Error: Network Error", "")],
    release:"a5f32dccadef2a192909b2ad384f3aa62948667f",
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
